







































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
  props: {
      initialValues: Object,
      loadingState: Object
  }
})
export default class StorageDialog extends Vue {
    data() {
        if(!this.$props.initialValues) {
            return {
                bucketInfo: {
                    type: 's3',
                    accessKeyId: '',
                    accessKeySecret: '',
                    endpoint: '',
                    region: '',
                    bucket: ''
                },
                buttonLabel: 'Connect',
                missingFields: {}
            }
        }

        return {
            bucketInfo: this.$props.initialValues,
            buttonLabel: 'Save Changes'
        }
    }

    get buttonDisabled() {
        return (this.$props.loadingState || {}).status == 'loading'
    }

    missingField(key: string) {
        try {
            return this.$data.missingField[key]
        } catch {
            return false
        }
    }

    submitForm() {
        const fields = ['bucket', 'accessKeyId', 'accessKeySecret', 'endpoint', 'region']

        const missingFields: any = {}

        for (const field of fields) {
            if(!this.$data.bucketInfo[field]) {
                missingFields[field] = true
            }
        }

        this.$data.missingFields = missingFields

        if(missingFields.length) {
            return
        }

        this.$emit('submit', this.$data.bucketInfo)
    }
}
