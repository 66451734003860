






import { Component, Vue } from 'vue-property-decorator';
import StorageDialog from '@/components/StorageDialog.vue'

@Component({
  components: {
    StorageDialog,
  },
  props: { 
    id: String
  }
})
export default class ChangeStorage extends Vue {

  get bucketData() {
    if(this.$props.id) {
      return this.$store.state.buckets.find((e: any) => e.id == this.$props.id)
    } else {
      return {
          type: 's3',
          accessKeyId: '',
          accessKeySecret: '',
          endpoint: '',
          region: '',
          bucket: ''
      }
    }
  }

  get loadingState() {
    return this.$store.state.loading.bucketSave
  }

  saveBucket(e: any) {
    this.$store.dispatch('saveBucket', e).then(() => {
      console.log("Bucket Saved!")
      this.$router.back()
    }).catch(() => {
      console.log("Failed to save bucket.")
      alert("Failed to save bucket, please try again.")
    })
  }

  created() {
    if(!this.bucketData) {
      this.$store.dispatch('loadBuckets')
    }
  }
}
